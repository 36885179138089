@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html{
  font-family: 'YourCustomFontNumber';
}
:root {
  --tw-bg-opacity: 0.5; /* Adjust the opacity as needed */
}
*{

}

body {
  font-family: 'YourCustomFont' !important;
  overflow-x: hidden;

}





.custom-accordion {
  background-color: #ff9500 !important; /* Change accordion background */
  color: #fff; /* Change text color */
  border-radius: 20px !important;
  margin-bottom: 10px;
  font-family: "Poppins", system-ui !important;
;

  transition: all 0.3s ease;
}

.custom-accordion:hover {
  background-color: #ffffff !important; /* Background color on hover */
  color: #000000; /* Text color on hover */
}

.accordion-title {
 
  font-size: 22px ;
  font-weight: 600;
  
}
.accordion-titlem {
 
  font-size: 15px ;
  font-weight: 600;
  
}

.MuiAccordionSummary-root:hover {
  cursor: pointer;
}



























































.ready{
  font-family: 'YourCustomFont' !important;
  text-align: center;
  font-size: 20px;

}
@font-face {
  font-family: 'YourCustomFont';
  src: url('./Fonts/Hardsoul\ Offc\ W00\ Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'YourCustomFontNumber';
  src: url('./Fonts/HIROMISAKE.ttf') format('truetype');
}


.ques{
  font-family: 'YourCustomFontNumber' ;
}
@font-face {

 
}
.fontz{
  font-family: 'YourCustomFont' !important;
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 1px 0;
  
}



.utcolor{
  color: #ff9f55 !important;
}



.shima_head {
  text-align: center;
  font-size: 110px;
  font-weight: bold;
color: #000;
  letter-spacing:10px; /* Add this to increase space between letters */
}
@media screen and (min-width: 1000px){
  .shima_head {
    text-align: center;
    font-size: 150px;
    font-weight: bold;
  color: #000;
    letter-spacing:10px; /* Add this to increase space between letters */
  }
  
}



.shima_headl {
  
  font-size: 65px;
  font-weight: bold;
  background: linear-gradient(45deg, #ffffff, #ffffff, rgb(241, 241, 241), #ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2),
               4px 4px 12px rgba(0, 0, 0, 0.15);

}



.centerall{
  text-align: center;
  justify-content: center;
  align-items: center;

}

/* Additional font-face rules if you have more fonts */

/* Define global font styles */
.matrix-text {
  color: rgb(255, 255, 255);
  font-family: 'YourCustomFont' !important;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


.sack_story_head1u{
  text-align: start;
  color: #fff;

  
  
  text-shadow: 
  -1px -1px 0 #000000,  
   1px -1px 0 #000000,  
  -1px  1px 0 #000000,  
   0px  1px 0 #000000;


}

.strongu{
  color: #ffa200;
}

.image_anos{
  width: 60%;
}
@media screen and (min-width: 1000px) {
                        
                                
  .image_anos{
    width: 50%;
  }

      }



.anos_text{
  font-size: 2rem !important;
  color: rgb(251, 251, 251);
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}





.anon_p{

  color: rgb(251, 251, 251);
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .anon_p{
 
    color: rgb(255, 255, 255);
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}

.image_anos_uni{
  width: 60%;
}

@media screen and (min-width: 1000px){
  .image_anos_uni{
    width: 50%;
  }
}

.image_anos_dex{
  width: 55%;
}

@media screen and (min-width: 1000px){
  .image_anos_dex{
    width: 45%;
  }
}

.blue-text {
  font-size: 1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .blue-text {
    color: #ffffff;
    font-size: 2.5rem !important;
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}



.red-text {
  font-size:1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .red-text {

    font-size: 2.5rem !important;
    color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}



.token_text{
  font-size: 1.4rem !important;
  color: rgb(255, 255, 255);

  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .token_text{
    font-size: 1.4rem !important;
    color: rgb(255, 255, 255);

    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}




.card {
    position: relative;
    width: auto;
    height: 100%;
    background-color: #000;
    border-radius: 8px;
}






























@import"https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap";.inputCls[type=number] {
  -moz-appearance: textfield
}


.footer {
 
  background-color: #25252500 !important;

}

.card {
  position: relative;
  width: auto;
  height: 100%;
  background-color: #000000c1 !important;
  border-radius: 8px
}

.card-content {
  padding: 20px
}

.card-title {
  margin: 0;
  font-size: 24px
}

.card-description {
  margin: 10px 0
}

.card-link {
  display: inline-block;
  padding: 8px 12px;
  background-color: #ff0400;
  color: #fff;
  text-decoration: none;
  border-radius: 4px
}

.boarding {
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border: 4px solid #bb00ff;
  pointer-events: none;
  border-radius: 12px;
  opacity: 0;
  animation: boardingAnimation 2s linear infinite
}

@keyframes boardingAnimation {
  0% {
      opacity: 0
  }

  50% {
      opacity: 1
  }

  to {
      opacity: 0
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat
}

*,:before,:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb
}

:before,:after {
  --tw-content: ""
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal
}



.tokcolo{
  color:  #fff !important;
  font-size: 3rem !important;
}




/* Style the audio container */
audio {
  background-color:transparent !important;
  color: white;
  width: 300px;
}

/* Style the audio controls */
audio::-webkit-media-controls {
  background-color: #a306067b;
  border-radius: 40%;
}

audio::-webkit-media-controls-play-button {
  background-color: #7c040481;
}

audio::-webkit-media-controls-volume-slider {
  background-color: #ff222263;
}

audio::-webkit-media-controls-panel {
  background-color: #860000d8;
  color: white;
}

/* Style the audio controls for Firefox */
audio::-moz-media-controls {
  background-color: #444;
  color: white;
}

/* Style the audio controls for Edge/IE */
audio::-ms-media-controls {
  background-color: #444;
  color: white;
}








.modal-content {
background-color: transparent;
  display: flex;
  border: none;
  flex-direction: column;

}

.sunken-panel{
  width: 780px;
  cursor: pointer;
  height: 420px;
  position: relative;
   background-color: transparent !important;
 border: none !important;
 right: 120px;
   top: 30px;
}


.sunken-panel {
 
}

.sunken-panel::before {
 
}

.sunken-panel:hover::before {
  background-color: rgba(0, 0, 0, 0.3); /* Lighter overlay on hover */
}

.sunken-panel:hover {
  filter: brightness(1.2); /* Brighten the background image */
}

.sunken-panel .hover-text {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.5rem;
  color: #000;
  text-align: center;
  transition: opacity 0.3s ease;
}

.sunken-panel:hover .hover-text {
  opacity: 1; /* Show the text on hover */
}



.sunken-pane2{
  width: 200px;
  cursor: pointer;
  height: 100px;
  position: relative;
   background-color: transparent !important;
 border: none !important;
 right: 220px;
   top: 50px;
}







/* Style for the container */
.containerx {
  position: relative;
  text-align: center;
  background-color: transparent;
  z-index: 2 !important;
  height: 52vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* Add a background if needed */
  overflow: hidden;
}

/* Hide the hover text initially */
.hover-text {
  opacity: 0;
  font-size: 24px;
  border-radius: 20px;
  padding: 20px;
  background-color: #000000c0;
  color: #ff8000; /* Set the color of the text */
  transition: opacity 0.5s ease, transform 0.5s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* To prevent interaction with the text */
}

/* Show and animate the text on hover */
.casino-link:hover .hover-text {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.1);
  animation: fadeInUp 0.5s ease;
}

/* Add an optional hover effect to the container itself */
.casino-link:hover .container {
  background-color: rgba(0, 0, 0, 0.6); /* Darken the background slightly */
  transition: background-color 0.3s ease;
}

/* Animation keyframes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}


























/* Style for the container */
.containerxx {
  position: relative;
  text-align: center;
  background-color: transparent;
  z-index: 2 !important;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* Add a background if needed */
  overflow: hidden;

height: 14vh;
}

/* Hide the hover text initially */
.hover-textx {
  opacity: 0;
  font-size: 20px;
  border-radius: 20px;
  padding: 20px;
  width: 200px;
  background-color: #000000;
  color: #ff8000; /* Set the color of the text */
  transition: opacity 0.5s ease, transform 0.5s ease;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* To prevent interaction with the text */
}

/* Show and animate the text on hover */
.casino-linkx:hover .hover-textx {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.1);
  animation: fadeInUp 0.5s ease;
}

/* Add an optional hover effect to the container itself */
.casino-linkx:hover .container {
  background-color: rgba(0, 0, 0, 0.6); /* Darken the background slightly */
  transition: background-color 0.3s ease;
}

/* Animation keyframes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
































































:root {
  --glow-color: hsla(0, 0%, 100%, 0.882);
}




.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.25em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
 background-color: #000000;
  perspective: 2em;
  font-family: Courier New,Courier,monospace;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(3, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}




.dextop{
  position: relative;
  top: 8px;
}

.popins{
  font-family: 'YourCustomFontNumber' !important;
}
.popinsf{
  font-family: 'YourCustomFontNumber' !important;
  font-size: 20px !important;
}



.doyou {
  font-family: "Poppins", system-ui !important;
  text-decoration: underline;
  text-underline-offset: 10px !important; /* Adjust the value as needed */
}

.typing-demo {
  font-family: "Poppins", system-ui !important;
  font-size: 14px !important;

}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: calc(100% + 2ch); /* Adjust width based on text */
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

.glowing-checkbox {
  top: 5px;
  right: 5px;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #555;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  /* Initial scale for animation */
  transform: scale(1);
}

.glowing-checkbox:checked {
  background-color: #000000;

  border-color: #000000;
  /* Glowing box shadow when checked */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  /* Smoothly scale up the checkbox when checked */
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
}

.checkbox-text {
  font-size: 12px !important;
  font-family: "Poppins", system-ui !important;
  color: #000000;
  margin-left: 10px;
  /* Add fade-in effect to the text */


  transition: all 0.4s ease;
}

.glowing-checkbox:checked + .checkbox-text {
  /* Fade in and slide the text when the checkbox is checked */
  opacity: 1;
  transform: translateX(0);
}

/* Animation when hovering over checkbox */
.glowing-checkbox:hover {
  /* Subtle glow effect when hovering */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  /* Slight scale effect on hover */
  transform: scale(1.1);
}


.Homepage{
 
  background-attachment: fixed;
  border: 10px solid #000;
  height: 100vh;
  background-image: url('./PC.gif')!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;

  /* Set the background color with opacity and color using a CSS variable */
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity)); /* Red color with variable opacity */
}




.styled-button {
  font-size: 35px !important;
  color: black;
  position: relative;
  left: 30px;
  top: 4px;
  background-color: #fefefe;
  border: 1px solid #2f2f3f;
  height: 44px;
  width: 110px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;
  cursor: pointer;
}

.styled-button:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}






.styled-buttonh {
  font-size: 35px !important;
  color: black;
  position: relative;
  left: 100px;
  top: 4px;
  background-color: white;
  border: 1px solid #2f2f3f;
  height: 44px;
  width: 180px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;
  cursor: pointer;
}

.styled-buttonh:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}









.button-container {
  display: flex;
  justify-content: end;
  text-align: end;



}






































.x-button {
  background-color: #000000; /* Button background color (brownish shade) */
  border: none;
  border-radius: 12px; /* Rounded corners */
position: relative;
top: 16px;
right: 35px;
  cursor: pointer;
height: 35px;
width: 38px;
  align-items: center;
  justify-content: center;
}

.x-icon {
  color: white; /* Icon color */
  font-size: 20px !important; /* Adjust the size of the icon */
}

.x-button:hover {
  background-color: #000000; /* Slightly darker color on hover */
}


.tw{
  position: relative;
  left:  0px !important;
}

.ds{
  position: relative;
  left:  25px !important;
}



.HomepageAbout {
  background-attachment: fixed;
  background-image: url('./PC.gif') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 100vh;
  border: 10px solid #000;

  position: relative; /* Allows for absolute positioning of inner content */
  overflow: hidden; /* Ensure content stays within the container */
}

.BlurBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-image: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px); /* Apply blur to only the background */
  z-index: 0; /* Keeps it behind the content */
}

.GlassOverlay {
  position: relative;
  width: 80%; /* Adjust to your desired size */
  padding: 20px;

  color: #000; /* Text color */
  z-index: 1; /* Keeps content above the blurred background */
  margin: 50px auto; /* Centers the overlay */
  text-align: center; /* Centers text inside */
}






























.about_container {
  display: flex;
  justify-content: center;
  align-items: center;

 
  margin: 0;
}

.about-button {
  padding: 20px 55px;
  border: 3px solid #000000; /* Dark purple border */
  background-color: #f3f3f3; /* White background */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Soft shadow for the button */
  border-radius: 5px; /* Slight rounding of the corners */
}

.about-button .text {
  
  font-size: 26px !important;
  text-shadow: #000 4px 0 0,
  #000 3.87565px .989616px 0,
  #000 3.51033px 1.9177px 0,
  #000 2.92676px 2.72656px 0,
  #000 2.16121px 3.36588px 0,
  #000 1.26129px 3.79594px 0,
  #000 .282949px 3.98998px 0,
  #000 -.712984px 3.93594px 0,
  #000 -1.66459px 3.63719px 0,
  #000 -2.51269px 3.11229px 0,
  #000 -3.20457px 2.39389px 0,
  #000 -3.69721px 1.52664px 0,
  #000 -3.95997px .56448px 0,
  #000 -3.97652px -.432781px 0,
  #000 -3.74583px -1.40313px 0,
  #000 -3.28224px -2.28625px 0,
  #000 -2.61457px -3.02721px 0,
  #000 -1.78435px -3.57996px 0,
  #000 -.843183px -3.91012px 0,
  #000 .150409px -3.99717px 0,
  #000 1.13465px -3.8357px 0,
  #000 2.04834px -3.43574px 0,
  #000 2.83468px -2.82216px 0,
  #000 3.44477px -2.03312px 0,
  #000 3.84068px -1.11766px 0,
  #000 3.9978px -.132717px 0,
  #000 0 4px 0;
  color: #000; /* Black text color */
}

.about-button .diamond {
  font-family: 'Arial', sans-serif;
  font-size: 22px;
  font-weight: normal;
  display: inline-block;
  transform: scale(1.2); /* Enlarge the diamond a little */
}































.paf{
  font-family: 'YourCustomFonts' !important;
}




.containera {
text-align: start;
font-family: 'YourCustomFonts' !important;
  margin: 50px auto;
  padding: 50px 20px 30px 30px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  line-height: 1.8;
  white-space: pre-line;
}

.container p {
  margin-bottom: 20px;

  font-size: 16px;
}





















































































.HomepageAboutha {

  background-image: url('./MC.gif');
 
}

.BlurBackgroundha {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  filter: blur(10px); /* Blurs the background */
  z-index: 0; /* Keep it behind other content */
}

.GlassOverlayha {
  position: relative;
  width: 100%; /* Adjust size as needed */
  padding: 20px;
 
  border-radius: 1px; /* Add smooth corners */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  backdrop-filter: blur(5px); /* Glass-like blur effect */
  
  color: #000;
  z-index: 1; /* Keeps content above the blurred background */
  margin: 0px auto; /* Centers the overlay horizontally */
  text-align: center;
}

















.containeram {
  text-align: start;

    padding:  40px;
    background-color: #fefefe;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    line-height: 1.8;
    white-space: pre-line;
  }
  























  .bgss{
    font-size: 30px !important;
    background-color: #f3f3f3 !important;
    text-align: center !important;
  }

  .Footerm {
    background-color: rgba(183, 183, 183, 0); /* Semi-transparent red */
    backdrop-filter: blur(0px); /* Blurs the background behind the footer */
    
   
    padding: 10px;
  }


  .Footer {
    background-color: rgba(183, 183, 183, 0); /* Semi-transparent red */
    backdrop-filter: blur(0px); /* Blurs the background behind the footer */
    
   
    padding: 50px;
  }
  .Footerzz {
    background-color: rgba(0, 0, 0, 0.17); /* Semi-transparent red */
    backdrop-filter: blur(1px); /* Blurs the background behind the footer */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    border-radius: 1px; /* Rounded corners for a more glass-like look */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    padding: 20px;
  }

  .banner_main{
    font-family: 'YourCustomFont' !important;
height: 100vh;
    background-image: url('./Components/casino//c8.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover !important;
  
    
  }

  .wood_divider {
    height: 141px;
    background-color: #000000dd !important;
    
    /* Add border only to the top side */
    border-top: 5px solid #000;
    border-bottom:  5px solid #000;;
  }
  

  .wood_dividermo {
 
    background-color: #000000dd !important;
    
    /* Add border only to the top side */
 
  }
  




































  
























































  

.HomepageAbouth {

 


  

}



.HomepageAbouths {
  background-image: url('./bgts.jpg'); /* Add your image */
  background-size: cover; /* Ensures the image covers the whole container */
/* Makes the parallax effect smoother */
  background-position: center  center  ;
  background-repeat: no-repeat;

  width: 100%;
}














.centeritallstart{
  display: flex;
  justify-content: start;
  text-align: start;
  align-items: start;
}











.centeritall{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}











/* CSS */
.button-82-pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-82-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

.button-82-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  
  -webkit-background-clip: text;

  text-shadow: 2px 2px 8px rgb(255, 0, 0),
               4px 4px 12px rgb(255, 255, 255);
  letter-spacing: 3px; /* Add this to increase space between letters */

  background: linear-gradient(45deg, #ffffff, #ffffff, rgb(241, 241, 241), #ffffff, #ffffff);
}

.button-82-front {
  display: block;
  position: relative;
  font-size: 1.1rem;
  background: linear-gradient(45deg, #ffffff, #ffffff, rgb(241, 241, 241), #ffffff, #ffffff);
  background-clip: text;
  
  -webkit-background-clip: text;

  text-shadow: 2px 2px 8px rgb(126, 0, 0),
               4px 4px 12px rgb(255, 255, 255);
  letter-spacing: 3px; /* Add this to increase space between letters */

  will-change: transform;
  transform: translateY(-4px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

@media (min-width: 768px) {
  .button-82-front {
    font-size: 1.25rem;
    padding: 12px 42px;
  }
}

.button-82-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
  transform: translateY(-6px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
  transform: translateY(4px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
  outline: none;
}










































.catextm{
  font-family: 'YourCustomFontNumber' !important;
  text-align: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
}





.catext{
  font-family: 'YourCustomFontNumber' !important;
}


.address-box {
  background-color: #3b3b3b; /* Slightly darker purple */
  padding: 10px;
  box-shadow: inset -3px -3px 0 #252525;
  border-radius: 20px;
border: 2px solid #000;
  font-family: "YourCustomFontCA", system-ui !important;
  font-size: 1.3rem;
  color: white;
width: 765px;
  text-align: center;
}

.address-boxm {
  background-color: #3b3b3b; /* Slightly darker purple */
  padding: 10px;
  box-shadow: inset -3px -3px 0 #252525;
  border-radius: 20px;
border: 2px solid #000;
color: #ffffff;
font-size: 12px;
  font-family: "YourCustomFontCA", system-ui !important;

}

.copy-btn {
  background-color: white;
  color: black;
  
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-family: "YourCustomFontCA", system-ui !important;
  cursor: pointer;
}

.copy-btn .icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.copy-btn:hover {
  background-color: #e0e0e0;
}





.shima_headfs {
 
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(45deg, #dddddd, #ffffff, rgb(241, 241, 241), #ffffff, #002338);
  background-clip: text;
  position: relative;

  -webkit-background-clip: text;
  color: transparent;


  letter-spacing: 2px; /* Add this to increase space between letters */
}








.shima_headf {
  text-align: center;
  font-size: 33px;
  font-weight: bold;
  background: linear-gradient(45deg, #ffffff, #ffffff, rgb(241, 241, 241), #ffffff, #ffffff);
  background-clip: text;
  position: relative;
  top: 9px;
  -webkit-background-clip: text;
  color: transparent;


  letter-spacing: 2px; /* Add this to increase space between letters */
}


.backofitfeelglassy {
  background-color: rgba(231, 0, 0, 0.858); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Creates the frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
border-top:3px solid #000 ;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  padding: 20px; /* Optional: Adds padding inside the element */
}


.disclaimer{
  color: #ffffff;
  font-size: 24px;
}




.backofitfeelglassym {
  background-color: rgba(255, 255, 255, 0.304); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Creates the frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
border-top:3px solid #000 ;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  padding: 7px; /* Optional: Adds padding inside the element */
}

.about_box{
  background-color: rgba(46, 46, 46, 0.951); /* Semi-transparent background */
  border-radius: 60px;

border: 3px solid #000;
  box-shadow: inset -12px -12px 0 #252525;
}

.about_boxm{
  background-color: rgba(46, 46, 46, 0.951); /* Semi-transparent background */
  border-radius: 20px;

border: 3px solid #000;
  box-shadow: inset -12px -12px 0 #252525;
}

.backoffly {
  background-color: #000 !important;
  border-radius: 40px;
  border: 2px solid #555;
  box-shadow: inset -16px -18px 0 #000000;
  transition: all 0.3s ease-in-out;
}
































































/* From Uiverse.io by adamgiebl */ 
.buttonfs {
  font-size: 22px;
  font-weight: 600;
  
  padding: 0.6em 1.8em;
  border: 1px solid #000;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
 background-color: #c61010;
  color: #fff;
  box-shadow: inset 0 0 10px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
}

/* Text color change and pop-up effect */
.buttonfs:hover {
  color: #000000;
  background-color: #ffffff !important;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6), 0 0 9px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(-0.4em); /* Adds the pop-up effect */
}

/* Animated gradient effect */
.buttonfs:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform .4s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(27, 253, 156, 0.1) 40%, rgba(27, 253, 156, 0.1) 60%, transparent 100%);
}

/* Gradient animation on hover */
.buttonfs:hover:before {
  transform: translateX(15em);
}



.banner_mainf{

  font-family: 'YourCustomFont' !important;

border-top: 6px solid #000;
  background-image: url('./Components//images/footer_under.png')!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;

  
}

.foot_under{
  padding: 25px;
  position: relative;
 left: 100px;
}


.footlogo{
  width: 55px;
  border: 2px solid #ffffff;
  border-radius: 18px;
}
.footlogop{
  width: 65px;
  height: 65px;
  border: 2px solid #000000;
  border-radius: 20px;
  position: relative;
  top: 5px;
}

.holdnav{
  padding: 5px;
}














.shima_headfzx {
  text-align: center;
  font-size: 30px;

color: #ff9865 !important;

  letter-spacing: 5px; /* Add this to increase space between letters */
}






body {
  background-color: #000; /* Set background color to black */
}

.shimano {
  display: flex; /* Ensure flex properties work */
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff; /* Text color */
  font-weight: 900;
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
  letter-spacing: 3px;
  font-size: 80px;
}


.shimanofa {
  display: flex; /* Ensure flex properties work */
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff; /* Text color */
  font-size: 100px;
  font-weight: 900;
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
  letter-spacing: 3px;
  font-size: 80px;
}






.about_boxshim {
  background-color: rgba(46, 46, 46, 0.951); /* Semi-transparent background */
  border-radius: 30px;
  border: 3px solid #000;
  width: 100%;
  height: 140px;
  box-shadow: inset -10px -10px 0 #252525;
  transition: all 0.4s ease; /* Smooth transition for hover effect */
}

.about_boxshim:hover {
  transform: scale(1.05); /* Slightly scale up on hover */
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.8), inset -10px -10px 0 #000000;
  background-color: rgba(66, 66, 66, 0.95); /* Change background color on hover */
}



.shimunder {
  text-align: center;
  color: #ffffff;

}
























.mute-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Ensure it appears above other content */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.mute-btn:hover {
  background-color: #333;
  transform: scale(1.05);
}


.move_to_left{
  position: relative;
  top: 10px;
  left: 70px;
}


.move_to_right{
  position: relative;
  top: 10px;
  left: 35px;
}



.desktop_CA{
  position: relative;
  left: 100px;
}



































/* From Uiverse.io by adamgiebl */ 
.buttonfsc {
  font-size: 22px;
  font-weight: 600;
  font-family: 'YourCustomFont' !important;
  padding: 0.4em 1em;
  border: 1px solid #000;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
 background-color: #c61010;
  color: #fff;
  box-shadow: inset 0 0 10px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
}

/* Text color change and pop-up effect */
.buttonfsc:hover {
  color: #000000;
  background-color: #ffffff !important;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6), 0 0 9px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(-0.4em); /* Adds the pop-up effect */
}

/* Animated gradient effect */
.buttonfsc:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform .4s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(27, 253, 156, 0.1) 40%, rgba(27, 253, 156, 0.1) 60%, transparent 100%);
}

/* Gradient animation on hover */
.buttonfsc:hover:before {
  transform: translateX(15em);
}


.caAvailableOn{
  color: #fff;
  font-size: 28px !important;
  position: relative;
  top: 15px;
}









































.buy__arrow {


width: 125px;
height: 80px;
background-color: #c61010;
color: #fff;
box-shadow: inset 0 0 13px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
border: 1px solid #000;
 position: relative;
top: 80px;
  color: rgb(110, 139, 193);
right: 40px;

  border-image: initial;
  border-radius: 32px;
}




.buy__arrowb {

z-index: 10;
  width: 125px;
  height: 80px;
  background-color: #c61010;
  color: #fff;
  box-shadow: inset 0 0 13px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #000;
   position: relative;
  top: 80px;
    color: rgb(110, 139, 193);
  right:  40px;
 
    border-image: initial;
    border-radius: 32px;
  }
  
  



.how-to-buy-boxa{
  background: #3b3b3b;
  box-shadow: inset -7px -7px 0 #252525;
  border: 3px solid #000000;
  border-radius: 35px;
  border-image: initial;
height: 280px;
 
  padding: 32px;


}
.how-to-buy-boxab{
  background: #3b3b3b;
  box-shadow: inset -7px -7px 0 #252525;
  border: 3px solid #000000;
  border-radius: 35px;
  border-image: initial;
  border-radius: 42px;
  padding: 32px;
  height: 280px;
 

  z-index: -1;

}


.how-to-buy-box {
  background: #3b3b3b;
  box-shadow: inset -7px -7px 0 #252525;
  border: 3px solid #000000;
  border-radius: 35px;
  height: 280px;
 
  border-image: initial;
  z-index: -1;
  border-radius: 42px;
  padding: 32px;
width: 100%;
  position: relative; /* Or absolute/fixed depending on your layout */
}


.arrow{
  text-align: center;
  justify-content: center;
  padding: 3px;
  font-size: 40px;
  color: #fff;

  position: relative;
  top: 3px;
}












.border_socials{
  font-weight: 900;
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
  letter-spacing: 3px;
}













































.shimanopa {
  display: flex; /* Ensure flex properties work */
  text-align: center;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  color: #fff; /* Text color */
  font-weight: 100;
  text-shadow: #000 2px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
  letter-spacing: 3px;

}












































.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure it's behind other content */
}

.home-container {
  position: relative; /* Ensure positioning is handled correctly */
}





.fontzm{
  font-family: 'YourCustomFont' !important;
  font-size: 22px !important;
  
}




.fontzmt{
  font-family: 'YourCustomFont' !important;
  font-size: 19px !important;
  
}













.questionnumber{
  font-family: 'YourCustomFontNumber';
}










.home-container {
  position: relative;
  overflow: hidden; /* Prevents scroll bars from appearing */
}

.mistVideo {
  position: fixed; /* Fixed position to cover the entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Ensure full width */
  height: 100%; /* Ensure full height */
  object-fit: cover; /* Ensure the video covers the entire area */
  z-index: 100; /* Behind other content */
  opacity: 1; /* Start fully visible */
  transition: opacity 2s; /* For the smooth transition */
}

.content {
  position: relative; /* Positioning context for the content */
  z-index: 1; /* Bring content above the background */
}












.modal-backdrop.show {
  opacity: .1 !important;
}













.modal_effect{
  font-size: 18px;
  font-weight: 100 !important;
  padding: 7px;
}

@media screen and (min-width: 1000px){
  .modal_effect{
    font-size: 25px;
  }
  
  
}



.footerzz{
  background-color: #2b2b2b !important;
}


.centeritallnow{
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
}























.HomepageAbouthsf {
  background-image: url('./bgts.jpg'); /* Add your image */
  background-size: cover; /* Ensures the image covers the whole container */
/* Makes the parallax effect smoother */
  background-position: center  center  ;
  background-repeat: no-repeat;
background-attachment: fixed;
  width: 100%;
}



































.shimanoms {

  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff; /* Text color */
  font-weight: 900;
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;

  font-size: 50px;
}













.how-to-buy-boxams{
  background: #3b3b3b;
  box-shadow: inset -7px -7px 0 #252525;
  border: 3px solid #000000;
  border-radius: 35px;
  border-image: initial;
height: 100%;
 
  padding: 16px;


}


















.buy__arrowms {



  background-color: #c61010;
  color: #fff;
  box-shadow: inset 0 0 13px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #000;
   position: relative;
  

 
  
    border-image: initial;
    border-radius: 32px;
  }
  

  .coverimg {
    display: block;            /* Ensure the image behaves like a block element */
    max-width: 100%;         /* Scale the image down if it's larger than the container */
    height: auto;            /* Maintain aspect ratio */
    margin: 0 auto;          /* Center the image horizontally */
  }
  






























































  .button_container {
  
  }
  
  .story_3d {
    width: 120px;
    height: 120px;
    background: rgba(255, 255, 255, 0.1); /* Glassy effect */
    backdrop-filter: blur(10px);
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.5); /* Glassy border */
    color: rgb(255, 255, 255);
    
    text-shadow: 
    -1px -1px 0 #000000,  
     1px -1px 0 #000000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    box-shadow: 0 10px 20px rgb(0, 0, 0);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .story_3d:hover {
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.35);
  }
  
  .details_box {
    position: absolute;
    top: 50%;
    background-color: black; /* Black background for details box */
    padding: 20px;
        
    text-shadow: 
    -1px -1px 0 #000000,  
     1px -1px 0 #000000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
    width: 200px;
    border: 1px solid #ddd;
    text-align: center;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    pointer-events: auto;
    color: white; /* White text for readability */
  }
  
  .details_box p, .details_box a {
    margin: 0;
    padding: 5px 0;
  }
  
  .details_box a {
    text-decoration: none;
    color: #3498db;
  }
  
  .details_box a:hover {
    text-decoration: underline;
  }
  




  .backs {
    background-image: url('./Components/casino/back.jpg') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-attachment: fixed;
    position: relative; /* Required for absolute positioning of overlay */
  }
  
  .glass-effect {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.752); /* Adjust opacity as needed */
    backdrop-filter: blur(10px); /* Adjust blur intensity */
    z-index: 1; /* Ensure it is below the text */
  }
  
  .sack_story_head {
    position: relative; /* Ensure text is above the glass effect */
    z-index: 2; /* Bring text above the glass effect */

  }
  






  .sack_story_head1{
    text-align: center;
    color: #fff;
    font-size: 70px;
    
    
    text-shadow: 
    -1px -1px 0 #000000,  
     1px -1px 0 #000000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
  }

  .sack_story_head1m{
    text-align: center;
    color: #fff;
    font-size: 45px;
    
    
    text-shadow: 
    -1px -1px 0 #000000,  
     1px -1px 0 #000000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
  }

  .sack_story_head12{
    text-align: center;
    font-family: "Poppins", sans-serif !important;
    color: #fff;
    font-size: 40px;
  }



  .sack_story_head12m{
    text-align: center;
    font-family: "Poppins", sans-serif !important;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
  }

































  














  .bottomnav {
    position: fixed; /* Fixes the navigation at the bottom */
    bottom: 0;
    left: 0;
   
    z-index: 2; /* Bring text above the glass effect */
    right: 0;
    background: #000;
    backdrop-filter: blur(10px); /* Apply blur for glassy effect */
    padding: 10px 20px; /* Adjust padding as needed */
    display: flex; /* Use flexbox for layout */
    justify-content: space-around; /* Space links evenly */
    border-top: 1px solid rgba(255, 255, 255, 0.5); /* Optional border for separation */
  }
  
  nav a {
    color: white; /* Adjust link color */
    text-decoration: none; /* Remove underline */
    padding: 10px; /* Add padding around links */
  }
  
  nav a:hover {
    color: #FFD700; /* Change color on hover */
  }
  
.modal-bodyz{
  background-color: #000 !important;
}






  .nav_bottom{
    font-size: 30px;
    text-shadow: 
    -1px -1px 0 #000000,  
     1px -1px 0 #000000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
  }






  .story-img{
    border: 10px solid #000;
  }










.home_mobilepage{
  background-image: url('./mobile.png');
  height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
  
  border: 5px solid #000;
}


























  .sunken-pane2 {
    
  }
  
  .sunken-pane2::before {

  }
  
  .sunken-pane2:hover::before {
    background-color: rgba(0, 0, 0, 0.3); /* Lighter overlay on hover */
  }
  
  .sunken-pane2:hover {
    filter: brightness(1.2); /* Brighten the background image */
  }
  
  .sunken-pane2 .hover-text2 {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.5rem;
    color: #000;    width: 100%;
    text-align: center;
    transition: opacity 0.3s ease;
  }
  
  .sunken-pane2:hover .hover-text2 {
    opacity: 1; /* Show the text on hover */
  }
  






.backsd{
 background-color: #000;
}




  .mobile-modal-image{
    width: 100%;
 
  }





















  

  .backgs{
    background-color: #000 !important;
  }


  .entercasino{
    background-color: transparent;
    height: 420px;
  }

  .mobile_button_aztec{
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
  }



























































  /* Hamburger Icon */
.hamburgerm {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.304); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Creates the frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  bottom: 0px; /* Adjust positioning as needed */
  left: 50%;
  transform: translateX(-50%);
  font-size: 28px; /* Adjust size as needed */
  width: 100%;
  text-align: center;
  align-items: center;
  
  color: white; /* Hamburger icon color */
  cursor: pointer;
  z-index: 10; /* Ensure it is above other elements */
}

/* Bottom Navigation */
.bottomnavm {
  position: fixed;
  bottom: -100%; /* Initially hidden */
  border: 1px solid #fff;
  left: 0;
  width: 100%;
  background-color: #000; /* Background color of the nav */
  padding: 20px 0; /* Padding for nav items */
  transition: bottom 0.3s ease; /* Smooth transition */
  z-index: 5; /* Ensure it is above other elements */
}

/* When the nav is open */
.bottomnavm.open {
  bottom: 0; /* Slide up when open */
}

/* Navigation Links */
.nav_bottomm {
  display: block;
  color: white; /* Text color */
  text-align: center; /* Center align text */
  padding: 15px 0; /* Padding for nav items */
  font-size: 20px; /* Font size */
  text-decoration: none; /* Remove underline */
}

/* Hover effect for links */
.nav_bottomm:hover {
  background-color: #444; /* Change background on hover */
}



























.hr{
  background-color: #fff;
}


a{
  text-decoration: none !important;
}

.backofHome {
  background: url('./Components/home.jpg') no-repeat center center;
  background-size: cover; /* Makes the image fully cover the element while maintaining aspect ratio */

  width: 100%; /* Full width */
  height: 100vh; /* Full viewport height */
text-align: center;
  align-items: center; /* Centers content vertically */
  justify-content: center; /* Centers content horizontally */
}

/* Optional: Adjustments for ultra-wide or curved screens */
@media (min-aspect-ratio: 16/9) {
  .backofHome {
    background-size: contain; /* For wider screens, it contains the image to avoid excessive cropping */
  }
}

/* Optional: Adjustments for smaller screens (e.g., mobile or tablet) */
@media (max-width: 768px) {
  .backofHome {
    height: auto; /* Ensures the image height is flexible on smaller screens */
    min-height: 100vh; /* Keeps the height consistent for mobile view */
  }
}





.Enter_the_Casino{

}





.backboxm{
  padding-top: 40px;
  padding-bottom: 10px;
  background-color: #000;
  padding-left: 70px;
  padding-right: 70px;
  border: 1px solid #fff;
}





.backbox{
  padding-top: 40px;
  padding-bottom: 10px;
  background-color: #000;
  padding-left: 70px;
  padding-right: 70px;
  border: 1px solid #fff;
}

.tyler{
  color: #fff;
  text-align: center;
}


